<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details title="Session details" @close-sidebar="closeSidebar">
                <session-details :uuid="uuid" @close-sidebar="closeSidebar"></session-details>
            </app-sidebar-details>
        </template>

        <div class="page-radius-sessions container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Radius Sessions</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-table
                        class="radius-sessions-table"

                        :cols="cols"
                        :rows="sessions"

                        :clickable="true"
                        :selected-row="uuid"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        @row-click="showSessionDetails"
                        @row-click-mobile="openSessionDetails"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>

        <app-loader v-if="loading"></app-loader>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'
import sessionDetails from '@/views/radius/session-details'

import appLoader from '@/components/app-loader'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        baseLayout,

        appSidebarDetails,
        sessionDetails,

        appLoader,
        appTable,
        appPagination,
    },

    data() {
        return {
            sessions_list: [],

            filter: {
                word: '',
                previous: '',
                sort: {
                    field: 'ConnectedAtNanos',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'Stack',               title: 'Stack'        },
                { field: 'CustomerName',        title: 'User Name'    },
                { field: 'NAS',                 title: 'NAS'          },
                { field: 'ConnectedAtNanos',    title: 'Connected'    },
                { field: 'DisconnectedAtNanos', title: 'Disconnected' },
            ],
            
            cols: [
                { key: 'stack',         title: 'Stack',        sort: { field: 'Stack' }               },
                { key: 'customer_name', title: 'Username',     sort: { field: 'CustomerName' }        },
                { key: 'nas',           title: 'NAS',          sort: { field: 'NAS' }                 },
                { key: 'connected',     title: 'Connected',    sort: { field: 'ConnectedAtNanos' }    },
                { key: 'disconnected',  title: 'Disconnected', sort: { field: 'DisconnectedAtNanos' } },
            ],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },
            
            uuid: '', // selected row

            loading: false,
        }
    },

    methods: {
        init(to, from) {
            const getRadiusSessions = () => { this.getRadiusSessions(to.params.page) }

            if (from.name && /^radius-session(-|$)/i.test(from.name)) {
                this.loadRadiusSessionsFilter().then(getRadiusSessions).catch(getRadiusSessions)
            } else {
                this.$store.dispatch('clearRadiusSessionsFilter').then(getRadiusSessions).catch(getRadiusSessions)
            }
        },

        loadRadiusSessionsFilter() {
            return this.$store.dispatch('loadRadiusSessionsFilter').then(loaded_filter => {
                let filter = {}

                for (const key in this.filter) {
                    filter[key] = key in loaded_filter
                        ? typeof this.filter[key] == 'string'
                            ? loaded_filter[key]
                            : {...this.filter[key], ...loaded_filter[key]}
                        : this.filter[key]
                }
                
                this.filter = filter
            }).catch(error => Promise.resolve(error))
        },

        getRadiusSessions(page, filter) {
            // console.group('getRadius(page) {')
            // console.log('page', page)

            this.loading = true

            filter = {
                ...this.filter,
                ...this.search,
                ...filter
            }
            // console.warn('filter', {...filter})

            let params = {
                'IncludeInactive': true,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize': this.pagination.limit,
                'SearchOptions.SortBy': filter.sort.field,
                'SearchOptions.SortDesc': filter.sort.order === 'desc' ? 1 : 0,
            }

            const word = filter.word.trim()
            // console.log('word', word)

            if (word.length > 0) {
                params = {
                    ...params,
                    'SmartSearch': word,
                    'SearchOptions.PageNumber': word !== filter.previous ? 1 : params['SearchOptions.PageNumber']
                }

                this.filter.word = word
                this.filter.previous = word
            }
            // console.log('params', {...params})

            this.$store.dispatch('getRadiusSessions', { params, filter }).then(response => {
                this.sessions_list = response.Sessions

                const { DisplayPageList, PageNumber, TotalItemCount } = response.PageInfo

                this.pagination.page = PageNumber
                this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                if (this.$route.params.page != this.pagination.page
                    && !(
                        this.$route.params.page === undefined
                        && this.pagination.page === 1
                    )
                ) {
                    this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                }

                this.loading = false
            }).catch(error => {
                console.error(error)

                this.sessions_list = []

                this.loading = false
            })

            // console.groupEnd()
        },



        /**
         * Filtering
         */
        onSearch(search) {
            // console.group('onSearch(search) {')
            // console.log('search', {...search})
            // console.log('search.word.trim()', search.word.trim())
            // console.log('this.filter.word.trim()', this.filter.word.trim())

            if (search.word.trim() != this.filter.word.trim()) {
                this.getRadiusSessions(this.pagination.page, { word: search.word })
            }

            // console.groupEnd()
        },

        onSortChange(sort) {
            this.filter.sort = sort
            this.getRadiusSessions()
        },

        onPageChange(page) {
            this.getRadiusSessions(page)
        },



        /**
         * Session Details
         */
        openSessionDetails(member) {
            this.$router.push({ name: 'member', params: { uuid: member.uuid } })
        },

        showSessionDetails(row) {
            if (this.uuid == row.uuid) {
                this.closeSidebar()
            } else {
                this.uuid = row.uuid
                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.uuid = null
            this.$emit('close-sidebar')
        },

        openSessionDetails(row) {
            this.$router.push({ name: 'radius-session-details', params: { uuid: row.uuid } })
        },
    },

    computed: {
        sessions() {
            return this.sessions_list.map(session => ({
                uuid: session.UUID,
                stack: session.Stack,
                customer_name: session.CustomerName,
                nas: session.NAS,
                connected: session.ConnectedAtNanos ? formatDateNano(session.ConnectedAtNanos) : '?',
                disconnected: session.DisconnectedAtNanos ? formatDateNano(session.DisconnectedAtNanos) : '-',
            }))
        },
    },
    
    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },

    beforeRouteLeave(to, from, next) {
        this.closeSidebar()
        next()
    },
}
</script>

<style lang="scss">
.page-radius-sessions {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .radius-sessions-table {
        @include table-cols-width((100px, 150px, 160px, 140px, 140px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-radius-sessions {
        .radius-sessions-table {
            @include table-cols-width((100px, 150px, 140px, 125px, 125px), true);
        }

        .app-add-mobile-session {
            margin-top: 30px;
        }
    }
}

@media (max-width: $mobile-size) {
    .page-radius-sessions {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .radius-sessions-table {
            margin-bottom: 15px;

            @include table-cols-width-mobile((85px, 190px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }

        .app-add-mobile-session {
            margin-top: 15px;
        }
    }
}
</style>